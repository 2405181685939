//@import "bootstrap/variables";


//== Colors

// Primary variations
$brand-primary-alt-darker-2:  #335070;
$brand-primary-alt-darker:    #2e5680;
$brand-primary-alt-dark:      #375579;
$brand-primary-alt-bright:    #00bed4;

$brand-primary-subtle-bright: #e1fcff;
$brand-primary-subtle-dark:   #e5e9f2;
$brand-primary-subtle-light:  #f3f7f9;

$brand-primary-hover:   #1d8ec7;
$brand-primary-active:  #0588b4;

$gray-base:       #000 !default;
$gray-darker:     lighten($gray-base, 13.5%) !default; // #222
$gray-dark:       lighten($gray-base, 20%) !default;   // #333
$gray:            lighten($gray-base, 33.5%) !default; // #555
$gray-light:      lighten($gray-base, 46.7%) !default; // #777
$gray-light-alt:  lighten($gray-base, 82.5%);
$gray-lighter:    lighten($gray-base, 93.5%) !default; // #eee
$gray-lightest:   lighten($gray-base, 98%);

$brand-primary:   #36aeea;
$brand-success:   #5cb85c;
$brand-info:      #5bc0de;
$brand-warning:   #f0ad4e;
$brand-danger:    #f67d7d;



//== Scaffolding

$body-bg:               #fff !default;
$text-color:            $gray-dark !default;
$text-color-alt:        #343d49;


//== Typography

$font-family-sans-serif:  "Open Sans", Helvetica, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;
$font-weight-base: 400;

$font-family-montserrat: "Montserrat", "Open Sans", Helvetica, Arial, sans-serif;

$font-size-base:          13px;
$font-size-large:         ceil(($font-size-base * 1.25)) !default;
$font-size-small:         ceil(($font-size-base * 0.85)) !default;

$font-size-h1: 24px;
$font-size-h2: 20px;
$font-size-h3: 16px;
$font-size-h4: 14px;
$font-size-h5: 14px;
$font-size-h6: 14px;

$line-height-base:        1.428571429 !default; // 20/14
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

$headings-font-family:    inherit !default;
$headings-font-weight:    500 !default;
$headings-line-height:    1.1 !default;
$headings-color:          $gray;


//== Components

$padding-base-vertical:     8px;
$padding-base-horizontal:   12px;

$border-radius-base:  8px;
$border-radius-large: 10px;
$border-radius-small: 4px;



//== Media queries breakpoints

$screen-xs-min: 480px !default;
$screen-sm-min: 768px !default;
$screen-md-min: 992px !default;
$screen-lg-min: 1200px !default;

$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//== Grid system

$grid-gutter-width: 30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-md-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Navbar

$navbar-height: 80px;
//$navbar-padding-horizontal:        floor(($grid-gutter-width / 2)) !default;
//$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2) !default;


//== Modals

$modal-inner-padding: 20px;
$modal-title-padding: 20px;

$modal-lg:    900px !default;
$modal-md:    600px !default;
$modal-sm:    300px !default;


//== Tables

$table-cell-padding:            8px !default;
$table-condensed-cell-padding:  5px !default; // .table-condensed`
$table-border-color:            #ddd !default;

//======================================================

//== Extras

// Useful for generic padding
$grid-gutter-half: ($grid-gutter-width / 2);
$grid-gutter-quarter: ($grid-gutter-width / 4);

